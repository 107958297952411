var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-expansion-panels',_vm._l((_vm.teams),function(t){return _c('v-expansion-panel',{key:t.team._id},[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(t.team.name)+": "+_vm._s(_vm._f("float2_0")(_vm.mannschaftgesamtergebnis(_vm.e, _vm.r, t.team._id)))+" Punkte ")]),_c('v-expansion-panel-content',_vm._l((_vm.d),function(g){return _c('v-card',{key:`${t.team._id}-${g._discipline}`},[_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(g.name)+": "+_vm._s(_vm._f("float2_0")(_vm.mannschaftgeraetergebnis(_vm.e, _vm.r, t.team._id, g._discipline)))+" Punkte ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.turner(t.team._id, g._discipline),"headers":[
                { text: 'Nr.', value: 'result.order', sortable: false, align: 'center' },
                { text: 'Turnerin', value: 'person', sortable: false },
                { text: 'D-Note', value: 'result.dscore', sortable: false, align: 'center' },
                { text: 'E-Note', value: 'result.escore', sortable: false, align: 'center' },
                { text: 'Penalty', value: 'result.penalty', sortable: false, align: 'center' },
                { text: 'Endnote', value: 'result.final', sortable: false, align: 'center' }
              ],"hide-default-footer":"","items-per-page":-1,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"item.person",fn:function({item}){return [_vm._v(" "+_vm._s(_vm._f("person")(item.person))+" ")]}},{key:"item.result.dscore",fn:function({item}){return [_vm._v(" "+_vm._s(_vm._f("float2_0")(item.result.dscore))+" ")]}},{key:"item.result.escore",fn:function({item}){return [_vm._v(" "+_vm._s(_vm._f("float2_0")(item.result.escore))+" ")]}},{key:"item.result.penalty",fn:function({item}){return [_vm._v(" "+_vm._s(_vm._f("float2_0")(item.result.penalty))+" ")]}},{key:"item.result.final",fn:function({item}){return [_c('span',{staticStyle:{"font-weight":"bold","font-size":"120%"}},[_vm._v(_vm._s(_vm._f("float2_0")(item.result.final)))])]}}],null,true)})],1)],1)}),1)],1)}),1),(_vm.topathletes.length > 0)?_c('v-card',{attrs:{"flat":""}},[_c('h2',[_vm._v("Beste Vierkämpferinnen")]),_c('table',{staticStyle:{"font-size":"100%"}},[_c('tr',{staticClass:"head"},[_c('td',[_vm._v("Turner")]),_c('td',[_vm._v("Mannschaft")]),_c('td',[_vm._v("Punkte")])]),_vm._l((_vm.topathletes),function(a,i){return _c('tr',{key:a.person._id,class:{even: i % 2 === 0}},[_c('td',[_vm._v(_vm._s(_vm._f("person")(a.person)))]),_c('td',[_vm._v(_vm._s(a.team.name))]),_c('td',[_vm._v(_vm._s(_vm._f("float2_0")(a.final)))])])})],2)]):_vm._e(),_c('v-btn',{staticClass:"mt-8",attrs:{"block":"","color":"primary"},on:{"click":function($event){return _vm.ergebnis(_vm.e, _vm.df, _vm.r)}}},[_vm._v("PDF-Download")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }