<template>
  <v-container fluid>
    <v-expansion-panels>
      <v-expansion-panel
        v-for="t in teams"
        :key="t.team._id"
      >
        <v-expansion-panel-header>
          {{ t.team.name }}: {{ mannschaftgesamtergebnis(e, r, t.team._id) | float2_0 }} Punkte
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card
            v-for="g in d"
            :key="`${t.team._id}-${g._discipline}`"
          >
            <v-card-title class="headline">
              {{ g.name }}: {{ mannschaftgeraetergebnis(e, r, t.team._id, g._discipline) | float2_0 }} Punkte
            </v-card-title>
            <v-card-text>
              <v-data-table
                :items="turner(t.team._id, g._discipline)"
                :headers="[
                  { text: 'Nr.', value: 'result.order', sortable: false, align: 'center' },
                  { text: 'Turnerin', value: 'person', sortable: false },
                  { text: 'D-Note', value: 'result.dscore', sortable: false, align: 'center' },
                  { text: 'E-Note', value: 'result.escore', sortable: false, align: 'center' },
                  { text: 'Penalty', value: 'result.penalty', sortable: false, align: 'center' },
                  { text: 'Endnote', value: 'result.final', sortable: false, align: 'center' }
                ]"
                hide-default-footer
                :items-per-page="-1"
                :mobile-breakpoint="0"
              >
                <template #item.person="{item}">
                  {{ item.person | person }}
                </template>
                <template #item.result.dscore="{item}">
                  {{ item.result.dscore | float2_0 }}
                </template>
                <template #item.result.escore="{item}">
                  {{ item.result.escore | float2_0 }}
                </template>
                <template #item.result.penalty="{item}">
                  {{ item.result.penalty | float2_0 }}
                </template>
                <template #item.result.final="{item}">
                  <span style="font-weight:bold;font-size:120%;">{{ item.result.final | float2_0 }}</span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card v-if="topathletes.length > 0" flat>
      <h2>Beste Vierkämpferinnen</h2>
      <table style="font-size:100%;">
        <tr class="head">
          <td>Turner</td>
          <td>Mannschaft</td>
          <td>Punkte</td>
        </tr>
        <tr
          v-for="(a, i) in topathletes"
          :key="a.person._id"
          :class="{even: i % 2 === 0}"
        >
          <td>{{ a.person | person }}</td>
          <td>{{ a.team.name }}</td>
          <td>{{ a.final | float2_0 }}</td>
        </tr>
      </table>
    </v-card>
    <v-btn block @click="ergebnis(e, df, r)" color="primary" class="mt-8">PDF-Download</v-btn>
  </v-container>
</template>

<script>
import { useCalc } from '@/views/components/stb_w_2023/plugins/calc'
import { usePdf } from '@/views/components/stb_w_2023/plugins/pdf'

export default {
  name: 'finished',

  setup (props, context) {
    return {
      ...useCalc(props, context),
      ...usePdf(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    r: {
      type: Array,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  computed: {
    d () {
      return this.geraete(this.e, this.df)
    },
    h () {
      return this.heim(this.e)
    },
    g () {
      return this.gast(this.e)
    },
    teams () {
      return this.e.teams.map(t => ({
        ...t,
        final: this.mannschaftgesamtergebnis(this.e, this.r, t.team._id)
      })).sort((a, b) => a.final > b.final ? -1 : 1)
    },
    topathletes () {
      return this.e?.topathletes?.filter(a => a.disciplines === 4)?.sort((a, b) => a.final < b.final ? 1 : -1) || []
    }
  },

  methods: {
    turner (tid, did) {
      return this.mannschaftturnerangeraet(this.e, this.r, tid, did)
    }
  }
}
</script>

<style scoped>

</style>
